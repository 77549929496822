.btn.btn-outline-secondary {
  margin-right: 10px;
}

.hiddenRow {
  padding: 0 !important;
}

.dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  overflow-y: scroll;
  height: 200px;
}

.dropdown:empty {
  border: none;
  height: 0px;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;
}
